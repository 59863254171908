<template>
  <div class="loading_container" v-if="loading">
    <div class="mask"></div>
    <a-spin :tip="loadingTips"></a-spin>
  </div>
</template>

<script>
import globalConfig from '../../store/global_config';
export default {
  computed: {
    loading() {
      return globalConfig.state.loading;
    },
    loadingTips() {
      return globalConfig.state.loadingTips || '请稍候';
    }
  }
};
</script>

<style lang="less" scoped>
.loading_container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  .mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
  }
}
</style>
