<template>
  <div class="book_outer_content">
    <!-- 选择教材 -->
    <div class="first_title">
      <a-tabs @change="firstNavChange">
        <a-tab-pane
          v-for="navItem in firstNavList"
          :key="navItem.id"
          :tab="navItem.name"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <div class="second_title" v-if="secondNavList.length > 0">
      <a-tabs @change="secondNavChange">
        <a-tab-pane
          v-for="navItem in secondNavList"
          :key="navItem.id"
          :tab="navItem.name"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <a-row class="book_list_container" :gutter="24">
      <my-loading v-show="loadingStatus"></my-loading>
      <no-data v-if="bookList.length === 0"></no-data>
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="8"
        :xl="8"
        v-for="bookItem in bookList"
        @click="chooseBookItem(bookItem)"
        :key="bookItem.id"
      >
        <div class="book_item">
          <img
            class="book_img"
            v-if="imgUrl"
            :src="imgUrl + bookItem.img_url"
            alt
          />
          <div class="book_right">
            <div class="book_name">{{ bookItem.title }}</div>
            <div class="book_study">{{ bookItem.study_num }}人学习</div>
            <div>
              <div class="btn_study">立即学习</div>
              <div @click.stop="conversionClick(bookItem)" v-for="type in bookItem.amount_type" :key="type.type" v-if="type.type == '2' && bookItem.is_free == 1 && bookItem.is_saled == 0" class="btn_study btn_conversion" >
                兑换码兑换
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-modal
      title="兑换"
      :visible="codeVisible"
      :confirm-loading="confirmLoading"
      @ok="handleCode"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
       <a-form-model
        ref="ruleForm"
        :model="codeForm"
        :rules="rules"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
      >
        <a-form-model-item label="兑换码" prop="code">
          <a-input v-model="codeForm.code"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getStartPageMenu, getBookPress, getBookList, getBookCode } from '../../api/service';
import {
  PUBLIC_DATA,
  showMyToast,
  showLoading,
  hideLoading
} from '../../utils/common';
const { imgUrl } = PUBLIC_DATA;
import NoData from '../../components/NoData';
import MyLoading from '../../components/MyLoading';
export default {
  components: { NoData, MyLoading },
  created() {
    this.showBookList();
  },
  data() {
    return {
      imgUrl: imgUrl,
      firstNavList: [],
      secondNavList: [],
      bookList: [],
      loadingStatus: false,
      currentNavId: '',
      codeVisible: false,
      confirmLoading: false,
      codeForm: {
        code: '',
        book_id: '',
        device_info: ''
      },
      rules: {
        code: [
          {required: true, message: '请输入兑换码'},
        ],
      }
    };
  },
  methods: {
    showBookList() {
      // 获取第一分类接口
      this.fetchFirstTitle();
    },

    fetchFirstTitle() {
      getStartPageMenu().then(response => {
        const data = response.data;
        this.firstNavList = data;
        if (data.length === 0) {
          showMyToast('w', '暂无书籍');
          return;
        }
        this.currentNavId = data[0].id;
        this.fetchSecondList();
      });
    },

    // 获取第二分类接口
    fetchSecondList() {
      getBookPress({ partId: this.currentNavId }).then(response => {
        const { data } = response;
        if (data.length <= 0) {
          this.secondNavId = 0;
          this.fetchBookList();
          return;
        }
        this.secondNavId = data[0].id;
        this.secondNavList = data;
        this.fetchBookList();
      });
    },

    // 获取图书列表
    fetchBookList() {
      this.loadingStatus = true;
      getBookList({
        partId: this.currentNavId,
        pressId: this.secondNavId
      })
        .then(response => {
          hideLoading();
          this.loadingStatus = false;
          this.bookList = response.data;
        })
        .catch(() => {
          hideLoading();
          this.loadingStatus = false;
        });
    },

    // 一级分类切换
    firstNavChange(event) {
      showLoading();
      this.currentNavId = event;
      this.fetchSecondList();
    },

    // 二级分类切换
    secondNavChange(event) {
      showLoading();
      this.secondNavId = event;
      this.fetchBookList();
    },

    // 选择当前书籍
    chooseBookItem(bookItem) {
      const { id } = bookItem;
      this.$router.push({ path: `/book/${id}` });
    },
    // 兑换码
    conversionClick (bookItem) {
      this.codeVisible = true
      this.codeForm.book_id = bookItem.id
    },
    handleCode () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          this.codeForm.device_info = JSON.stringify({
            platform: navigator.userAgentData.platform,
            model: navigator.userAgentData.brands[0].brand,
            version: navigator.userAgentData.brands[0].version,
            system: navigator.appVersion.substring(0, navigator.appVersion.indexOf(';')),
            brand: navigator.appVersion.substring(navigator.appVersion.indexOf(';') + 1, navigator.appVersion.indexOf(')')).trim()
          })
          getBookCode(this.codeForm).then(res => {
            this.codeVisible = false
            this.confirmLoading = false
            this.$message.success('兑换成功');
            this.codeForm = {
              code: '',
              book_id: '',
              device_info: ''
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    handleCancel () {
        this.codeVisible = false
        this.codeForm = {
          code: '',
          book_id: '',
          device_info: ''
        }
    }
  }
};
</script>

<style lang="less" scoped>
.book_outer_content {
  padding: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.book_list_container {
  flex: 2;
  padding-left: 20px;
  overflow-y: scroll;
  .book_item {
    height: 180px;
    display: flex;
    background: #ffffff;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    .book_img {
      width: 105px;
    }
    .book_right {
      flex: 2;
      padding-left: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .book_name {
        font-size: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        width: 70%;
        -webkit-line-clamp: 2;
      }
      .book_study {
        font-size: 14px;
        color: #faad14;
      }
      .btn_study {
        font-size: 14px;
        width: 88px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        background: #1890ff;
        color: white;
        text-align: center;
        float: left;
      }
      .btn_conversion {
        background: white;
        color: #1890ff;
        margin-left: 10px;
        line-height: 30px;
        border: 1px solid #1890ff;
      }
    }
  }
}
// .book_list_content {
//   display: flex;
//   flex-wrap: wrap;
//   flex: 2;
//   overflow-y: scroll;
//   position: relative;
//   .book_item {
//     text-align: center;
//     margin-bottom: 20px;
//     .left {
//       .book_img {
//         width: 185px;
//         min-height: 248.61px;
//       }
//     }
//     .right {
//       margin-top: 10px;
//       .title {
//         width: 100%;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//       }
//     }
//   }
//   .inner_left_right {
//     .inner_right {
//       color: #fe9900;
//     }
//   }
// }
</style>
